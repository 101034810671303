<template>
    <div>
        <div class="container-fluid">
            <div class="container my-5 py-5">
                <div class="d-flex flex-column align-items-center">
                    <img class="col-8 col-lg-4 mb-5" :src="vector.url" alt="" />
                    <h1 class="col-12 col-lg-8 fw-bold text-center text-darkblue my-4">
                        COMPANY REGISTRATION SERVICES
                    </h1>
                    <div class="col-12 col-lg-6 text-center mb-5">
                        Avoid the pile of government forms, you just have to provide your information and documents to us, we will register your company for you
                    </div>
                    <div class="col-12 col-lg-8 card price-card my-5 rounded-3">
                        <div class="card-body p-4 p-lg-5 row">
                            <div class="col-12 col-lg-3 pb-4 pb-lg-0 fw-bold">
                                <h5 class="text-darkgrey">Pricing **</h5>
                                <h3 class="text-blue">THB 20,000</h3>
                            </div>
                            <div class="col-12 col-lg-9">
                                <p>Our service includes the following :</p>
                                <ul>
                                    <li v-for="(service, index) in services" :key="index" class="py-1">
                                        {{ service }}
                                    </li>
                                </ul>
                                <i class="small text-grey">
                                    ** Prices excluding government fees (6,410 THB)
                                </i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
.price-card {
    border-color: var(--royal);
    border-top: 12px solid var(--royal);
}
li {
    list-style: "—   ";
}
</style>
<script>
export default {
    data() {
        return {
            vector: {
                url: "https://firebasestorage.googleapis.com/v0/b/jnpaccounting-5fe5f.appspot.com/o/imgs%2Fvector%2Fcertificate.svg?alt=media&token=295dd4c2-f897-4cb2-a012-35d2b9efccb4"
            },
            services: [
                "Complete your online application in Thai language",
                "Screens your company information & documents",
                "Submits your application with the Department of Business Development (DBD)",
                "Help-desk support to answer all your questions"
            ]
        }
    }
}
</script>